<template>
  <div 
    id="navbar"
    :class="{ 'navbar-el-orange-bg':navElOrangeBg }"
    >
    <v-container class="pa-0">
			<v-layout>
        <v-flex xs12 xl8 offset-xl2 class="px-2">
          <el-navbar v-bind="navbarProps" @logout="logout">{{flowDisplayName}}</el-navbar>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ElNavbar from '@explorelearning/navbar';
import { mapState, mapGetters } from 'vuex';
import SubscriptionHelper from 'mixins/SubscriptionHelper'
import Forwarding from 'mixins/Forwarding'
import LoginDetector from 'mixins/LoginDetector'
import APIs from 'mixins/APIs'
import OrderLoader from 'mixins/OrderLoader'
import ShoppingCart from 'mixins/ShoppingCart'
import ProductInfo from '../../mixins/ProductInfo';

export default {
  components: { ElNavbar },
  mixins: [SubscriptionHelper, Forwarding, LoginDetector, APIs, OrderLoader, ShoppingCart, ProductInfo],
  data () {
    return {
      hideNavProp: true
    }
  },
  computed: {
    ...mapState('platformData/app', ['authenticated', 'claims']),
    ...mapGetters('platformData/classes', ['classList']),
    ...mapGetters('platformData/institutions', ['institutionList']),
    ...mapGetters('platformData/students', ['studentList']),
    ...mapGetters('platformData/subscriptions', ['subscriptionList']),
    ...mapGetters('platformData/teachers', ['teacherList']),
    ...mapGetters('platformData/profile', ['profile']),
    allSiteSubscriptions(){
      let subs = this.$store.state.store.subscriptions
      if(subs){
        let allSite = true
        subs.forEach(sub => {
          if(sub.licensingType.toLowerCase()!=='site'){
            allSite = false
          }
        })
        return allSite
      } else {
        return false
      }
    },
    navElOrangeBg() {
      return this.$route.meta.navElOrangeBg
    },
    flowDisplayName(){
      let flow = this.$route.meta.flow
      if(flow=='class') {
        this.hideNavProp = false
        if(this.isTeacher){
          return this.$t('flow.class')
        } else {
          return this.$t('flow.user')
        }
      } else {
        this.hideNavProp = true
        return null
      }
    },
    helpLinks() {
      let helpLinks = [
        {
          logic: this.hasSubscription('gizmos'),
          text: `Gizmos ${this.$t('help.PDOD')}`,
          icon: 'file-video',
          url: 'https://gizmoscp.videoshowcase.net/',
          extLinkIcon: true
        },
        {
          logic: this.hasSubscription('reflex') && this.isTeacher,
          text: `Reflex ${this.$t('help.teacherGuide')}`,
          icon: 'file',
          url: this.getHelpCenterURL('article/Reflex-Teacher-Guide'),
          extLinkIcon: true
        },
        {
          logic: this.hasSubscription('reflex'),
          text: `Reflex ${this.$t('help.PDOD')}`,
          icon: 'file-video',
          url: 'https://reflex.videoshowcase.net/',
          extLinkIcon: true
        },
        {
          logic: this.hasSubscription('frax'),
          text: `Frax ${this.$t('help.PDOD')}`,
          icon: 'file-video',
          url: 'https://frax.videoshowcase.net/',
          extLinkIcon: true
        },
        {
          logic: this.hasSubscription('s4us'),
          text: `Science4Us ${this.$t('help.PDOD')}`,
          icon: 'file-video',
          url: 'https://science4us.videoshowcase.net',
          extLinkIcon: true
        },        
      ]
      let applicableLinks = []
      helpLinks.forEach(link => {
        if(link.logic){
          applicableLinks.push(link)
        }
      })
      return applicableLinks
    },
    hideCartBoolean() {
      let showCartLicenseType = false 
      this.subscriptions.forEach(sub => {
        if (sub.licensingTypeID == 3 || sub.licensingTypeID == 8) {
          let id = sub.productID
          if (this.productSettings[id].purchaseable) {
            showCartLicenseType = true
          }
        }
      })

      if (this.shoppingCartSize > 0 || showCartLicenseType) {
        return false
      } else {
        return true
      }
    },
    hideLauncherMenu() {
      return this.$route.meta.hideLauncherMenu || this.isStudent
    },
    hideManageUsersMenu() {
      return this.$route.meta.hideManageUsersMenu || this.isStudent || this.$store.getters.getIsClassCodeEnabledUser && this.isTeacher
    },
    isAdmin(){
        return this.$store.getters["platformData/profile/isAdmin"]
    },
    isDistrictAdmin(){
        if(this.isAdmin && this.institutionList){
            return this.institutionList.length>1
        } else {
            return false
        }
    },
    isStudent(){
      return this.profile.roleID == 2 
    },
    isTeacher() {
      return this.profile.roleID == 3
    },
    navbarProps() {
      return {
        // platform-related props
        authenticated: this.authenticated && this.loggedIn,
        claims: this.claims,
        profile: this.profile,
        platformModel: {
          classes: this.classList,
          institutions: this.institutionList,
          users: {
            students: this.studentList,
            teachers: this.teacherList
          }
        },
        routerOverrides: {
          profile: {name: 'Account'},
          manageUsers: {name: 'UserManagement'},
          manageUsersTeacherTab: {name: 'UserManagement', query: {tab: 'teachers'}},
          manageUsersStudentTab: {name: 'UserManagement', query: {tab: 'students'}},
          cart: {name: 'Cart'},
          home: {name: 'Home'}
        },

        // menu component props
        profileMenuProps: {},
        manageUsersMenuProps: {
          showDropdown: this.isAdmin && !this.isDistrictAdmin
        },
        launcherMenuProps: {},
        helpMenuProps: {
          showSearch: false,
          enableSupportForm: false,
          links: this.helpLinks,
        },

        // display props
        aboutTextBrandMenu: true,
        dark: true,
        darkBrandLogoCustomFill: this.$route.meta.navElOrangeBg ? '#F58220' : null,
        fixed: false,
        colorFixedBackground: false,
        boxLogo: false,
        invertLogo: false,
        hideLogo: false,
        hideNav: this.hideNavProp,
        hideProfileMenu: false,
        hideManageUsersMenu: this.hideManageUsersMenu,
        hideLauncherMenu: this.hideLauncherMenu,
        hideHelpMenu: this.isStudent,
        hideBrandMenu: this.isStudent,
        hideRequestInfoMenu: true,  
        hideCart: this.hideCartBoolean,
        homeRoute: { name: 'Home' },
        mainLogoLeafVersion: true,
        product: 'el',
        shoppingCartSize: this.shoppingCartSize,
        shoppingCartUrl: this.shoppingCartUrl,
      };
    },
    shoppingCartUrl() {
      return this.$router.resolve({ name: 'Cart' }).href
    },
    tabOverride() {
      return this.$route.meta.tabOverride
    }
  },  
  watch: {
    //We are watching for route changes here if it changes and the taboverride meta is not present
    //we will reset the tab order so your first tab will hit the EL logo in the header
    $route () {
      if (!this.tabOverride) {
        this.$nextTick(function() {
          this.resetTabOrder();						
        })
      };
    },
  },
  created(){
    if (this.userJTI && !this.isStudent) {
      this.$http.get(this.getOrderExistsAPI(),this.jtiHeader).then(response => {
        if(response.data.Boolean){
          this.loadOrder()
        }
      }, error => {
        console.log(error)
      })
    }
  },
  methods: {
    resetTabOrder() {
      let app = document.getElementById('app');
      app.setAttribute("tabindex", "-1");
      app.focus();
      app.removeAttribute("tabindex");
    },
    logout() {
      this.go({name:'Logout'});
    },
  },  
}
</script>

<style lang="less" scoped>
.navbar-el-orange-bg {
  background-color: #F5821F
}
</style>