<template>
    <UserManagmentModal
        :value="value"
        :headline="headline"
        :subHeadline="subHeadline"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            <div class="title mb-3">{{$t('classMgmt.sure')}}</div>
            {{bodySubject}} {{$t('classMgmt.willBeRemoved')}} {{$t('classMgmt.theSchoolRoster')}}
            <span v-if="inviteMode">{{$tc('classMgmt.invalidateEmails',selectedUsers.length)}}</span>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" flat @click="deleteUsers(selectedUsers)" class="elevation-0">
                <v-icon>delete</v-icon>
                <span v-if="inviteMode">&nbsp;{{$tc('classMgmt.inviteHeader', this.selectedUsers.length)}}</span>
                <span v-else>&nbsp;{{$t('classMgmt.delete')}} {{$tc('classMgmt.' + userType.toLowerCase(), selectedUsers.length)}}</span>
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'DeleteUserModal',
        components: { UserManagmentModal },
        props: {
            isTeacher: Boolean,
            selectedUsers: Array,
            userCountOrName: Function,
            userType: String,
            value: Boolean,
            inviteMode: Boolean
        },
		computed : {
            bodySubject(){
                if(this.inviteMode){
                    return this.inviteCountOrEmail(this.selectedUsers, this.$tc('classMgmt.' + this.userType.toLowerCase()) + ' ' + this.$tc('classMgmt.invite',this.selectedUsers.length), true).toLowerCase()
                } else {
                    return this.userCountOrName(this.selectedUsers, this.$tc('classMgmt.' + this.userType.toLowerCase(), this.selectedUsers.length), true)
                }
            },
            headline(){
                if(this.inviteMode){
                    return this.$tc('classMgmt.inviteHeader', this.selectedUsers.length)
                } else {
                    return this.$tc('classMgmt.deleteConfirmHeader', this.selectedUsers.length)
                }
            },
            platform(){
                 return this.$store.state.platform
            },
            subHeadline(){
                if(this.inviteMode){
                    return this.inviteCountOrEmail(this.selectedUsers, this.$tc('classMgmt.' + this.userType.toLowerCase()) + ' ' + this.$tc('classMgmt.invite',this.selectedUsers.length))
                } else {
                    return this.userCountOrName(this.selectedUsers, this.$tc('classMgmt.' + this.userType.toLowerCase(), this.selectedUsers.length))
                }
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            deleteUsers(users) {
                this.$emit('deleteUsers', users)
            },
            inviteCountOrEmail(users, descriptor, ignoreDescriptor) {
                if(users.length>1){
                    return users.length + ' ' + descriptor
                } else if(users.length==1) {
                    return (ignoreDescriptor ? '' : descriptor + ': ') + users[0].email
                } else {
                    return ''
                }
            }
        },
	}
</script>

<style lang="less" scoped>
</style>