<template>
	<v-dialog v-model="display" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{$t('siteLicenseModal.headline')}}</v-card-title>
        <v-card-text>
        	{{$t('siteLicenseModal.price')}}
        	<br/><br/>
					{{$t('siteLicenseModal.info')}} <a :href="getMarketingPurchaseURL()" target="_blank">reflexmath.com/buy</a>.
				</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat v-if="totalSeats<95" @click="proccedWithCheckout()">
          	{{$t('siteLicenseModal.cartBtn')}}
          </v-btn>
          <v-btn color="primary" :to="{name:'Quote'}">
          	{{$t('siteLicenseModal.quoteBtn')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
	import APIs from "mixins/APIs"
	import LoginDetector from "mixins/LoginDetector"
	import ShoppingCart from 'mixins/ShoppingCart'
  export default {
  	name: 'Subscriptions',
  	mixins: [APIs, LoginDetector, ShoppingCart],
  	data(){
  		return {
  			display: false
  		}
  	},
  	computed: {
  		displayTrigger(){
  			return this.$store.state.eventBus.siteLiscenseModal
  		},
  		totalSeats(){
  			if(this.$store.state.eventBus.cartItemLimbo.currentSeats){
  				return this.$store.state.eventBus.cartItemLimbo.currentSeats + this.$store.state.eventBus.cartItemLimbo.seats
  			} else {
  				return this.$store.state.eventBus.cartItemLimbo.seats
  			}
  		}
  	},
  	watch: {
  		displayTrigger(val){
  			if(val){
  				this.display = true
  				this.$store.commit('setSiteLicenseModal', false)
  			}
  		}
  	},
  	methods: {
  		proccedWithCheckout(){
  			let cartItem = this.$store.state.eventBus.cartItemLimbo
  			this.addToCart(cartItem.orderItemID, cartItem.seats, cartItem.years, {modalBypass: true})
  			this.display = false
  			if(this.loggedIn){
  				this.$router.push({name:'Cart'})
  			} else {
  				this.$router.push({name:'CheckoutAccountInfo'})
  			}
		}
  	}
  }
</script>

<style lang="less">

</style>
