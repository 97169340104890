<template>
	<v-flex v-if="order">
		<v-layout wrap>
			<Headline :logo="currentEnvironment" :headline="pageTitle"></Headline>
		</v-layout>
		<v-flex v-if="orderFail" xs12 md10 offset-md1 class="mb-4">
  		<v-alert outline color="error" icon="info" :value="true">
	      {{$t('confirm.orderFail')}}
	    </v-alert>
  	</v-flex>
		<v-layout wrap>
			<v-flex xs12 sm6 class="mb-4">
				<v-flex class="title mb-2">
					{{$t('confirm.contact')}}
					<v-btn small flat icon v-if="!orderComplete" disabled><!--This buton is a spacer---></v-btn>
				</v-flex>
				<v-flex class="pl-3">{{userInfo.firstName}}&nbsp;<span v-if="userInfo.middleName">{{userInfo.middleName}}&nbsp;</span>{{userInfo.lastName}}</v-flex>
				<v-flex class="pl-3">{{userInfo.email}}</v-flex>
				<v-flex class="pl-3">{{userInfo.phone}}<span v-if="userInfo.phoneExt">&nbsp;({{userInfo.phoneExt}})</span></v-flex>
			</v-flex>
			<v-flex xs12 sm6 class="mb-4" v-if="order" :class="{'orderFail':orderFail}">
				<v-flex class="title mb-2">
					{{$t('confirm.card')}}
					<v-btn small flat icon floating v-if="!orderComplete" @click="shortcut('BillingInfo')">
						<v-icon small>edit</v-icon>
					</v-btn>
				</v-flex>
				<v-flex class="pl-3">{{paymentInfo.creditCardInfo.fullName}}</v-flex>
				<v-flex class="pl-3">{{paymentInfo.creditCardInfo.cardNumberClean}} ({{paymentInfo.creditCardInfo.cardTypeDisplay}})</v-flex>
				<v-flex class="pl-3">{{$t('confirm.expires')}}: {{paymentInfo.creditCardInfo.expMonth}}/{{paymentInfo.creditCardInfo.expYear}}</v-flex>
			</v-flex>
			<v-flex xs12 sm6 class="mb-4">
				<v-flex class="title mb-2">
					{{$t('address.header')}}
					<v-btn small flat icon floating v-if="!orderComplete" @click="shortcut('Address')">
						<v-icon small>edit</v-icon>
					</v-btn>
				</v-flex>
				<v-flex class="pl-3">{{paymentInfo.billingAddress.address1}}</v-flex>
				<v-flex class="pl-3" v-if="paymentInfo.billingAddress.address2">{{paymentInfo.billingAddress.address2}}</v-flex>
				<v-flex class="pl-3">{{paymentInfo.billingAddress.city}}, {{paymentInfo.billingAddress.state}} {{paymentInfo.billingAddress.postalCode}}</v-flex>
				<v-flex class="pl-3" v-if="paymentInfo.billingAddress.countryName">{{paymentInfo.billingAddress.countryName}}</v-flex>
				<v-flex class="pl-3" v-else>{{paymentInfo.billingAddress.countryCode}}</v-flex>
			</v-flex>
			<v-flex xs12 sm6 class="mb-4">
				<v-flex class="title mb-2">
					{{$t('address.shipping')}}
					<v-btn small flat icon floating v-if="!orderComplete" @click="shortcut('Address')">
						<v-icon small>edit</v-icon>
					</v-btn>
				</v-flex>
				<v-flex class="pl-3">{{paymentInfo.shippingAddress.address1}}</v-flex>
				<v-flex class="pl-3" v-if="paymentInfo.shippingAddress.address2">{{paymentInfo.shippingAddress.address2}}</v-flex>
				<v-flex class="pl-3">{{paymentInfo.shippingAddress.city}}, {{paymentInfo.shippingAddress.state}} {{paymentInfo.shippingAddress.postalCode}}</v-flex>
				<v-flex class="pl-3" v-if="paymentInfo.shippingAddress.countryName">{{paymentInfo.shippingAddress.countryName}}</v-flex>
				<v-flex class="pl-3" v-else>{{paymentInfo.shippingAddress.countryCode}}</v-flex>
			</v-flex>
			<v-flex v-if="schoolInfo.schoolType.toLowerCase()!='home'" xs12 class="mb-4">
				<v-flex class="title mb-2" :class="{'pb-2':!hasNewSubscription}">
					{{$t('confirm.institution')}}
					<v-btn v-if="hasNewSubscription && !orderComplete" small flat icon floating @click="shortcut('CheckoutSchoolInfo')">
						<v-icon small>edit</v-icon>
					</v-btn>
				</v-flex>
				<v-flex>
					<v-flex class="pl-3">{{order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.name}}</v-flex>
					<v-flex class="pl-3">{{order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.parentName}}</v-flex>
					<v-flex class="pl-3">
						<span v-if="order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.city">
							{{order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.city}},&nbsp;
						</span>
						<span v-if="order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.stateCode">
							{{order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.stateCode}}
						</span>
						<span v-else>
							{{order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.countryCode}}
						</span>
					</v-flex>
				</v-flex>
			</v-flex>
			<v-flex xs12 v-if="order">
				<v-data-table :headers='headers' :items='Object.values(orderItems)' hide-actions>
					<template slot="items" slot-scope="props">
			      <td>
			      	<span v-if="props.item.orderItem.orderItemType=='Purchase'">
			      		{{$t('cart.new')}} {{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}}
			      	</span>
			      	<span v-if="props.item.orderItem.orderItemType=='Expansion'">
			      		{{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}} {{$t('cart.expansion')}}
			      	</span>
			      	<span v-if="props.item.orderItem.orderItemType=='ExpandPending'">
			      		{{$t('cart.pending')}} {{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}} {{$t('cart.expansion')}}
			      	</span>
			      	<span v-if="props.item.orderItem.orderItemType=='Renew'">
			      		{{productDisplayName(props.item.orderItem.productID)}} {{$t('cart.subscription')}} {{$t('cart.renewal')}}
			      	</span>
			      </td>
			      <td class="text-xs-center">{{props.item.seats}}</td>
			      <td class="text-xs-center">
			      	<span v-if="props.item.fullYears">{{props.item.fullYears}} {{$t('cart.years')}}</span>
			      	<span v-if="props.item.fullYears && props.item.roundedMonths"><br/></span>
			      	<span v-if="props.item.roundedMonths">{{props.item.roundedMonths}} {{$t('cart.months')}}</span>
			      </td>
						<td class="text-xs-center relative">
							${{props.item.pricePerSeatForTotalDuration.toFixed(2)}}
						</td>
						<td class="text-xs-right">${{props.item.price.toFixed(2)}}</td>
			    </template>
			    <template slot="footer">
					    	<td colspan="2">
					    		<v-flex v-for="promo in promos" key="promo.promoCode" class="pt-2 pl-4">
					      		{{$t('cart.promotion')}}: {{promo.promoCode}}<span v-if="promo.promoMessage"> - {{promo.promoMessage}}</span>
					      	</v-flex>
					    	</td>
					      <td colspan="3" class="text-xs-right pt-2 pr-4">
					        <v-flex>{{$t('confirm.tax')}} (USD): ${{order.totalTax.toFixed(2)}}</v-flex>
									<v-flex><strong>{{$t('confirm.total')}} (USD): ${{order.totalPrice.toFixed(2)}}</strong></v-flex>
					      </td>
					    </template>
				</v-data-table>
			</v-flex>
		</v-layout>
		<form @submit.prevent="purchase" v-if="!orderComplete">
			<v-layout pl-3 class="text-xs-right">
				<v-flex mb-2>
					<v-checkbox
			      :label="$t('confirm.coppaAgree')"
			      v-model="agree"
			      v-validate="'required'"
			      name="agree"
			      :error-messages="errors.collect('agree')"
			    ></v-checkbox>
		  	</v-flex>
		  </v-layout>
		  <v-flex class="px-2">
		  	<COPPA></COPPA>
		  </v-flex>
			<v-layout wrap class="mt-3">
				<v-flex xs6>
					<v-btn :to="{name:'Address'}" large color="grey lighten-2">
						<i class="material-icons">keyboard_arrow_left</i>
						{{$t('navigation.back')}}
					</v-btn>
				</v-flex>
				<v-flex xs6 class="text-xs-right">
					<v-btn color="primary" large type="submit" :disabled="processingPurchase || !agree">
						{{$t('form.purchase')}}
					</v-btn>
				</v-flex>
			</v-layout>
		</form>
		<v-flex v-else class="pa-4 text-xs-center">
			<p>{{$t('confirm.orderComplete')}}</p>
			<v-btn v-for="product in productsInOrder" :key="product" color="primary" large class="mx-1" :href="getReportingAppURL(product.toLowerCase())">
				{{$t('confirm.goto')}}&nbsp;{{product}}
			</v-btn>
			<v-flex>
				<v-btn color="primary" flat large class="mx-1" :to="{name:'OrderHistory'}">
					{{$t('confirm.viewOrderHistory')}}
				</v-btn>
			</v-flex>
		</v-flex>
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import COPPA from 'components/legal/COPPA'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import OrderLoader from 'mixins/OrderLoader'
	import ProductInfo from 'mixins/ProductInfo'
	import ShoppingCart from 'mixins/ShoppingCart'
	import { mapActions, mapGetters } from 'vuex'
	export default {
		name: 'CheckoutConfirm',
		metaInfo() {
			return {
				title : this.orderComplete ? 'Success': 'Confirm'
			}
			
		},
		components: {
			COPPA,
			Headline
		},
		mixins: [APIs, ErrorHandling, LoginDetector, OrderLoader, ProductInfo, ShoppingCart],
		data(){
  		return {
  			agree: null,
  			order: null,
  			orderComplete: false,
  			orderFail: false,
  			headers: [
  				{text: this.$t('cart.product'), value:'product', sortable: false, align: 'left'},
  				{text: this.$t('cart.students'), value:'seats', sortable:false, align: 'center'},
  				{text: this.$t('cart.duration'), value:'years', sortable:false, align: 'center'},
  				{text: this.$t('cart.unitCost'), value: 'pricePerSeat', sortable: false, align: 'center'},
  				{text: this.$t('cart.cost'), value: 'totalPrice', sortable: false, align: 'right'},
  			],
  			processingPurchase : false
  		}
  	},
  	computed: {
		...mapGetters('platformData/profile', ['profile']),
  		paymentInfo(){
  			if(this.order){
  				return this.order.purchaseContactInfo
  			} else {
  				return {}
  			}
  		},
  		orderItems(){
  			if(this.order){
  				return this.order.orderLineItemMap
  			} else {
  				return {}
  			}
  		},
  		productsInOrder(){
  			let products = []
  			for(let index in this.orderItems){
  				let item = this.orderItems[index]
  				let productName = this.productDisplayName(item.productID)
  				if(productName && !products.includes(productName)){
  					products.push(productName)
  				}
  			}
  			return products
  		},
  		promos(){
  			let promos = []
  			for(let index in this.orderItems){
  				let item = this.orderItems[index]
  				if(item.promoCodeApplied){
  					promos.push({promoCode:item.promoCode,promoMessage:item.promoCodeMessage})
  				}
  			}
  			return promos
  		},
  		schoolInfo(){
  			if(this.order){
  				return this.order.purchaseContactInfo.schoolInfo
  			} else {
  				return {}
  			}
  		},
  		userInfo(){
  			return this.profile
  		},
  		pageTitle(){
  			if(!this.orderComplete){
  				return this.$t('confirm.headerReview')
  			} else {
  				return this.$t('confirm.headerConfirm')
  			}
  		}
  	},
    methods: {
		...mapActions('platformData/app', ['fetchModel', 'updateAuthClaims']),
		...mapActions('platformData/profile', ['fetchProfile']),
    	purchase: async function(){
    		var valid = await this.$validator.validateAll()
    		if(valid){
	    		this.processingPurchase = true
	    		await this.$http.post(this.getCheckoutReviewAPI(),null,this.jtiHeader).then(async response=>{
					await this.$http.post(this.getCheckoutPurchaseAPI(),null,this.jtiHeader).then(async response=>{
						var userInfo = this.decodeXclaims(response)
						await this.updateAuthClaims({ claims: {...this.claims, jti: userInfo.jti} })
						this.$store.commit('setJTI', userInfo.jti)
						this.resetCart()
						this.orderComplete = true
						if(typeof window.dataLayer != 'undefined'){
							var dataObject = {
							event : 'purchase',
							accountType : 'teacher'
							}
							if(this.schoolInfo.schoolType.toLowerCase()=='home'){
							dataObject.accountType = 'home'
							}
						window.dataLayer.push(dataObject);
						}
					},response=>{
						this.orderFail = true
						this.processingPurchase = false
						if(response.status==401){
							this.expiredCredentials()
						}
					})
				},response=>{
					this.processingPurchase = false
					if(response.status==401){
						this.expiredCredentials()
					}
				})
				await this.fetchProfile()
				await this.fetchModel()
			}
    	},
    	shortcut(pathName){
    		this.$store.commit('setCheckoutShortcutTaken', true)
    		this.$router.push({name:pathName})
    	}
    },
		created(){
			this.loadOrder()
			this.$http.post(this.getCheckoutReviewAPI(),null,this.jtiHeader).then(response=>{
				this.order = response.data.OrderDto
			},response=>{
				if(response.status==401){
  				this.expiredCredentials()
  			}
				console.log(response)
			})
		}
	}
</script>

<style lang="less" scoped>
	.title {
		border-bottom: solid 1px black;
	}
	.orderFail {
		color: red;
		.title {
			border-bottom: solid 1px red;
		}
		button {
			color:red;
		}
	}
</style>
