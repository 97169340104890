<template>
	<form @submit.prevent="submitInfo">
		<v-layout wrap>
			<Headline :logo="currentEnvironment" :headline="$t('billing.header')"></Headline>
		</v-layout>
		<v-layout wrap>
			<Messaging outline></Messaging>
			<v-flex xs12 sm8 md5>
				<v-text-field
					v-model="cardInfo.firstName"
					:label="$t('form.firstName')"
					:hint="$t('form.onCC')"
					v-validate="'required|max:30|noTags|noEscapes'"
					name="first name"
					:error-messages="errors.collect('first name')">
				</v-text-field>
			</v-flex>
			<v-flex xs12 sm4 md2>
				<v-text-field
					v-model="cardInfo.middleInitial"
					:label="$t('form.middleInitial')"
					:hint="$t('form.onCC')"
					v-validate="'length:1|noEscapes'"
					name="middle initial"
					:error-messages="errors.collect('middle initial')">
				</v-text-field>
			</v-flex>
			<v-flex xs12 sm12 md5>
				<v-text-field
					v-model="cardInfo.lastName"
					:label="$t('form.lastName')"
					:hint="$t('form.onCC')"
					v-validate="'required|max:30|noTags|noEscapes'"
					name="last name"
					:error-messages="errors.collect('last name')">
				</v-text-field>
			</v-flex>
			<v-flex xs8 sm6 md4>
				<v-text-field
					v-model="cardInfo.cardNumber"
					:label="$t('form.cardNumber')"
					v-validate="'required|credit_card'"
					:mask="creditCardMask"
					@focus="creditCardDirty=true"
					name="credit card number"
					:error-messages="errors.collect('credit card number')">
				</v-text-field>
			</v-flex>
			<v-flex xs4 sm6 md2>
				<v-text-field
					v-model="cardInfo.cardCode"
					:label="$t('form.CCV')"
					v-validate="'required|integer|min:3|max:4'"
					mask="####"
					name="CCV"
					:error-messages="errors.collect('CCV')">
				</v-text-field>
			</v-flex>
			<v-flex xs6 sm4 md2>
				<v-autocomplete
					:items="months"
					v-model="cardInfo.expMonth"
					:label="$t('form.expMonth')"
					v-validate="'required'"
					name="expiration month"
					:error-messages="errors.collect('expiration month')">
				</v-autocomplete>
			</v-flex>
			<v-flex xs6 sm4 md2>
				<v-autocomplete
					:items="years"
					v-model="cardInfo.expYear"
					:label="$t('form.expYear')"
					v-validate="'required'"
					name="expiration year"
					:error-messages="errors.collect('expiration year')">
				</v-autocomplete>
			</v-flex>
	   		<v-flex xs12 sm4 md2>
				<v-autocomplete
					:items="cardTypes"
					v-model="cardInfo.cardType"
					:label="$t('form.cardType')"
					v-validate="'required'"
					name="card type"
					:error-messages="errors.collect('card type')"
					single-line
					menu-props="bottom">
				</v-autocomplete>
			</v-flex>
			<v-flex xs8 sm6>
				<v-text-field
					v-model="phone"
					:label="$t('form.phoneNum')"
					v-validate="'required|numeric'"
					name="phone number"
					:error-messages="errors.collect('phone number')">
				</v-text-field>
			</v-flex>
			<v-flex xs4 sm6>
				<v-text-field
					v-model="phoneExt"
					:label="$t('form.ext')"
					v-validate="'noTags'"
					name="phone extension"
					:error-messages="errors.collect('phone extension')">
				</v-text-field>
			</v-flex>
			<v-flex xs6>
				<v-btn :to=backBtnDestination large color="grey lighten-2">
					<i class="material-icons">keyboard_arrow_left</i>
					{{$t('navigation.back')}}
        		</v-btn>
			</v-flex>
			<v-flex xs6 class="text-xs-right">
				<v-btn color="primary" large type="submit">
					<span v-if="this.$store.state.navigation.checkoutShortcutTaken">{{$t('navigation.review')}}</span>
					<span v-else>{{$t('navigation.next')}}</span>
				</v-btn>
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import autoFill from 'mixins/AutoFill'
	import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
	import OrderLoader from 'mixins/OrderLoader'
	import Tracking from 'mixins/Tracking'
	import { mapGetters } from 'vuex'
  export default {
    name: 'BillingInfo',
    metaInfo : {
			title : 'Billing Information'
		},
    components: {
    	Headline,
    	Messaging
    },
    mixins: [autoFill, APIs, EnvironmentDetector, ErrorHandling, LoginDetector, OrderLoader, Tracking],
    data () {
      return {
      	cardInfo : {
        	firstName : '',
        	lastName : '',
        	middleInitial : '',
			cardNumber : '',
        	cardCode : '',
        	expMonth : '',
        	expYear : '',
			cardType : ''
		},
		creditCardDirty : false,
      	phone : '',
      	phoneExt : '',
        cardTypes : [
        	{text:'American Express', value:'AMEX'},
        	{text:'Mastercard', value:'MASTERCARD'},
        	{text:'Visa', value:'VISA'},
        ]
      }
    },
    computed: {
		...mapGetters('platformData/profile', ['profile']),
    	backBtnDestination(){
    		if(this.$store.state.navigation.checkoutSkipSchool){
    			return {name: 'Cart'}
    		} else {
    			return {name:'CheckoutSchoolInfo'}
    		}
		},
		creditCardMask(){
			if(this.creditCardDirty) {
				return 'credit-card'
			} else {
				return 'xxxx - xxxx - xxxx - '+this.cardInfo.cardNumber.substring(12)
			}
		},
    	months(){
    		return [
  				{text: this.$t('month.jan'), value:'01'},
  				{text: this.$t('month.feb'), value:'02'},
  				{text: this.$t('month.mar'), value:'03'},
  				{text: this.$t('month.apr'), value:'04'},
  				{text: this.$t('month.may'), value:'05'},
  				{text: this.$t('month.jun'), value:'06'},
  				{text: this.$t('month.jul'), value:'07'},
  				{text: this.$t('month.aug'), value:'08'},
  				{text: this.$t('month.sep'), value:'09'},
  				{text: this.$t('month.oct'), value:'10'},
  				{text: this.$t('month.nov'), value:'11'},
  				{text: this.$t('month.dec'), value:'12'},
    		]
    	},
    	years(){
    		let years = []
    		let currentYear = (new Date()).getFullYear()
    		for(let i = 0; i < 21; i++){
    			years.push({text: currentYear+i, value: ((currentYear+i)%100).toString()})
    		}
    		return years
		}
    },
    created(){
    	this.loadOrder()
		this.autoFill()
    	if(!this.cardInfo.firstName && this.profile.firstName){
    		this.cardInfo.firstName = this.profile.firstName
    	}
    	if(!this.cardInfo.lastName){
    		this.cardInfo.lastName = this.profile.lastName
    	}
    },
    methods: {
		submitInfo: async function (){
			var valid = await this.$validator.validateAll()
			if(valid){
				if(this.years[0].value==this.cardInfo.expYear){
					let currentMonth = (new Date()).getMonth()+1
					if(this.cardInfo.expMonth<currentMonth){
						this.$store.commit('setMessageError', this.$t('error.expiredCard'))
						return
					}
				}
				let cardInfo = this.cardInfo
				cardInfo.phone = this.phone
				cardInfo.ext = this.phoneExt
				this.$http.post(this.getAddUpdateCheckoutCreditCardInfoAPI(),cardInfo,this.jtiHeader).then(response => {
					this.$store.commit('setCardInfo', cardInfo)
					let contactInfo = {
							firstName : this.cardInfo.firstName || this.profile.firstName,
							lastName : this.cardInfo.lastName || this.profile.lastName,
							email : this.$store.state.formData.email || this.cardInfo.email || this.profile.email,
							phone : this.phone,
							PhoneExt : this.phoneExt,
							formInfo : this.addTrackingInfo()
						}
						this.$http.post(this.getAddUpdateCheckoutContactInfoAPI(),contactInfo,this.jtiHeader).then(response => {
							this.$store.commit('setPhone', this.phone)
							this.$store.commit('setPhoneExt', this.phoneExt)
							if(this.$store.state.navigation.checkoutShortcutTaken){
								this.$store.commit('setCheckoutShortcutTaken', false)
								this.$router.push({name:'CheckoutConfirm'})
							} else {
								this.$router.push({name:'Address'})
							}
						}, error => {
							if(error.response.status==401){
							this.expiredCredentials()
						}
						})
				}, error => {
					if(error.response.status==401){
						this.expiredCredentials()
					}
				})
			}
		}
    }
  }
</script>
