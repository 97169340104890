export default {
	data(){
		return {
		}
	},
	computed : {
		assignmentIDs(){
			return [
				{id:0, label:'No Assignment', short:'No Assignment', family:'none'},
				// {id:1, label:'Adding and Subtracting 0 - 9', short:'ADD/SUB (0-9)'},
				{id:2, label:'Adding and Subtracting 0-10', short:'ADD/SUB (0-10)', family:'Addition and Subtraction'},
				// {id:3, label:'Adding and Subtracting 0 - 12', short:'ADD/SUB (0-12)'},
				// {id:4, label:'Multiplying and Dividing 0 - 9', short:'MUL/DIV (0-9)'},
				{id:5, label:'Multiplying and Dividing 0-10', short:'MUL/DIV (0-10)', family:'Multiplication and Division'},
				{id:6, label:'Multiplying and Dividing 0-12', short:'MUL/DIV (0-12)', family:'Multiplication and Division'}
			]
		},
		sectorIDs(){
			return [
				{id:0, label:'No Assignment', short:'No Assignment', desc: null},
				{id:1, label:'Sector 1', short:'Sector 1', desc:'Sector 1 is designed for students in grades 3 and above and covers foundational fractions skills. Sector 1 is the starting point for all students using Frax. Students that finish all available missions in Sector 1 will access the Sector Review room to practice all the skills and concepts they have learned across the entire Sector.'},
				{id:2, label:'Sector 2', short:'Sector 2', prerequisite:1, minGrade:5, desc:'Sector 2 is designed for students in grades 4+ and continues a student’s journey after completing Sector 1.  Assigning students access to Sector 2 will close access to the Sector 1 Review room, and prompt students to play new missions in Sector 2.'},
			]
		},
		productAccess(){
			return [
				{id:0, value: 0, code: 0, label: 'Access Disabled', short: 'Disabled'},
				{id:1, value: 1, code: 1, label: 'Access Enabled', short: 'Enabled'}
			]
		},
		ethnicities(){
			return [
				{ id: 0, label: 'Not Provided'},
				{ id: 11, label: 'Hispanic / Latino'},
				{ id: 12, label: 'American Indian / Alaska Native'},
				{ id: 13, label: 'Asian'},
				{ id: 14, label: 'Black / African American'},
				{ id: 15, label: 'Native Hawaiian / Pacific Islander'},
				{ id: 16, label: 'White / Caucasian'},
				{ id: 17, label: 'Two or more races'},
			]
		},
		genders(){
			return [
				{ id: 0, label: 'Not Provided'},
				{ id: 1, label: 'Female'},
				{ id: 2, label: 'Male'},
			]
		},
		gradeLevelIDs(){
			return [
				// { id: 0, label: 'Not Provided'},
				{ id: 1, label: 'Kindergarten'},
				{ id: 2, label: 'Grade 1'},
				{ id: 3, label: 'Grade 2'},
				{ id: 4, label: 'Grade 3'},
				{ id: 5, label: 'Grade 4'},
				{ id: 6, label: 'Grade 5'},
				{ id: 7, label: 'Grade 6'},
				{ id: 8, label: 'Grade 7'},
				{ id: 9, label: 'Grade 8'},
				{ id: 10, label: 'Grade 9'},
				{ id: 11, label: 'Grade 10'},
				{ id: 12, label: 'Grade 11'},
				{ id: 13, label: 'Grade 12'}
			]
		},
		languages(){
			return [
				{ id: 1, label: 'English', value:'English'},
				{ id: 2, label: 'Spanish (Reflex Only)', value:'Spanish'},
				{ id: 3, label: 'French (Reflex Only)', value:'French'},
			]
		},
		yesNoNA(){
			return [
				{ id: 0, label: 'Not Provided'},
				{ id: 1, label: 'Yes'},
				{ id: 2, label: 'No'},
			]
		},
		userTitles(){
			return [
				{id:0, label:''},
				{id:1, label:this.$t('form.titles.mr')},
				{id:2, label:this.$t('form.titles.ms')},
				{id:3, label:this.$t('form.titles.mrs')},
				{id:4, label:this.$t('form.titles.miss')},
				{id:5, label:this.$t('form.titles.dr')}
			]
		},
		daysInMonths(){
			return {
				1:31,
				2:28,
				3:31,
				4:30,
				5:31,
				6:30,
				7:31,
				8:31,
				9:30,
				10:31,
				11:30,
				12:31
			}
		},
		months(){
    		return [
  				{text: this.$t('month.jan'), value:'01', id:1},
  				{text: this.$t('month.feb'), value:'02', id:2},
  				{text: this.$t('month.mar'), value:'03', id:3},
  				{text: this.$t('month.apr'), value:'04', id:4},
  				{text: this.$t('month.may'), value:'05', id:5},
  				{text: this.$t('month.jun'), value:'06', id:6},
  				{text: this.$t('month.jul'), value:'07', id:7},
  				{text: this.$t('month.aug'), value:'08', id:8},
  				{text: this.$t('month.sep'), value:'09', id:9},
  				{text: this.$t('month.oct'), value:'10', id:10},
  				{text: this.$t('month.nov'), value:'11', id:11},
  				{text: this.$t('month.dec'), value:'12', id:12},
    		]
		},
		days(){
			return [
				{id:1, label:'1st'},
				{id:2, label:'2nd'},
				{id:3, label:'3rd'},
				{id:4, label:'4th'},
				{id:5, label:'5th'},
				{id:6, label:'6th'},
				{id:7, label:'7th'},
				{id:8, label:'8th'},
				{id:9, label:'9th'},
				{id:10, label:'10th'},
				{id:11, label:'11th'},
				{id:12, label:'12th'},
				{id:13, label:'13th'},
				{id:14, label:'14th'},
				{id:15, label:'15th'},
				{id:16, label:'16th'},
				{id:17, label:'17th'},
				{id:18, label:'18th'},
				{id:19, label:'19th'},
				{id:20, label:'20th'},
				{id:21, label:'21st'},
				{id:22, label:'22nd'},
				{id:23, label:'23rd'},
				{id:24, label:'24th'},
				{id:25, label:'25th'},
				{id:26, label:'26th'},
				{id:27, label:'27th'},
				{id:28, label:'28th'},
				{id:29, label:'29th'},
				{id:30, label:'30th'},
				{id:31, label:'31st'}
			]
		},
		roleIDs(){
			return {
				TEACHER 	: 1,
				STUDENT 	: 2,
				TEACHER 	: 3, /* eslint-disable-line */
				PARENT 		: 4,
				ADMIN 		: 5,
				WEB 		: 10,
				TRAINER		: 90,
				DEVELOPER 	: 100,
				ELADMIN 	: 101,
				PD 			: 102,
				MARKETING 	: 103,
				CS 			: 104,
				SALES 		: 105
			}
		},
		tabIDs(){
			return {
				ADMIN	: 0,
				TEACHER	: 1,
				STUDENT	: 2
			}
		},
		institutionTypeIDs(){
            return {
                SUPER_DISTRICT      : 1,
                DISTRICT            : 2,
                SUB_DISTRICT        : 3,
                PUBLIC_SCHOOL       : 4,
                PRIVATE_SCHOOL      : 5,
                STATE_DEPARTMENT    : 6,
                COUNTY_CENTER       : 7,
                REGIONAL_CENTER     : 8,
                HOME                : 9
            }
        },
		productExclusionList() {
			return [10,100,101]
		},
		environments() {
			return ["el","gizmos","reflex","frax","s4us"]
		}
	},
	methods : {
		differentAssignmentFamilies(id1,id2){
			let assignment1 = this.assignmentIDs.find(function(assignment){
				return assignment.id == id1
			})
			let assignment2 = this.assignmentIDs.find(function(assignment){
				return assignment.id == id2
			})
			return assignment1.family != assignment2.family
		},
		getAssignmentFamily(id){
			let assignment = this.assignmentIDs.find(function(assignment){
				return id == assignment.id
			})
			if(assignment){
				return assignment.family
			} else {
				return null
			}
		},
		getAssignmentLabel(id){
			let assignment = this.assignmentIDs.find(function(assignment){
				return id == assignment.id
			})
			if(assignment){
				return assignment.label
			} else {
				return null
			}
		},
		getAssignmentShort(id){
			let assignment = this.assignmentIDs.find(function(assignment){
				return id == assignment.id
			})
			if(assignment){
				return assignment.short
			} else {
				return null
			}
		},
		getDays(numDays){
			return this.days.slice(0,numDays)
		},
		getDaysInMonth(month){
			return this.daysInMonths[month]
		},
		getSectorDesc(id){
			let sector = this.sectorIDs.find(function(sector){
				return id == sector.id
			})
			if(sector){
				return sector.desc
			} else {
				return null
			}
		},
		getSectorLabel(id){
			return this.sectorIDs.find(function(sector){
				return id == sector.id
			}).label
		},
		getSectorShort(id){
			return this.sectorIDs.find(function(sector){
				return id == sector.id
			}).short
		},
		getEthnicity(id){
			const ethnicity = this.ethnicities.find(function(ethnicity){
				return id == ethnicity.id
			})
			if (ethnicity) {
				return ethnicity.label
			} else {
				return null
			}
		},
		getGender(id){
			const gender = this.genders.find(function(gender){
				return id == gender.id
			})
			if(gender){
				return gender.label
			} else {
				return null
			}
		},
		getGradeLevel(id){
			if(!id){
				return 'Not Provided'
			} else {
				return this.gradeLevelIDs.find(function(level){
					return level.id == id
				}).label
			}
		},
		getGradeLevelID(gradeName){
			let gradeID = 0
			let level = this.gradeLevelIDs.find(function(level){
				return level.label == gradeName
			})
			if(level){
				gradeID = level.id
			}
			return gradeID
		},
		getLanguage(id,value){
			value = value || 'label'
			if(id){
				return this.languages.find(function(language){
					return language.id == id
				})[value]
			}
			return null
		},
		getLanguageID(langName){
			let langID = 0
			let language = this.languages.find(function(lang){
				return lang.label == langName
			})
			if(language){
				langID = language.id
			}
			return langID
		},
		getProductAccess(value){
			return this.productAccess.find(option => {
				return option.value == value
			})
		},
		getTitle(titleID){
			let correctTitle = ""
			this.userTitles.forEach(function(title){
				if(title.id == titleID){
					correctTitle = title.label
				}
			})
			return correctTitle
		},
		getYesNoNA(id){
			let yn = this.yesNoNA.find(function(yesNo){
				return id == yesNo.id
			})
			if(yn){
				return yn.label
			} else {
				return ''
			}
		}
	}
}
