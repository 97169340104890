import APIs from 'mixins/APIs'
import AutoFill from 'mixins/AutoFill'
import LoginDetector from 'mixins/LoginDetector'
import ShoppingCart from 'mixins/ShoppingCart'
export default {
	mixins: [APIs, AutoFill, LoginDetector, ShoppingCart],
	data(){
		return {
			orderRequested: false,
			orderLoaded: false
		}
	},
	methods: {
		loadOrder(){
			if(this.orderLoaded){
				this.autoFill()
			} else {
				if(this.orderRequested){
					this.loadOrder()
				} else {
					this.orderRequested = true
					if(this.shoppingCartSize==0){
						this.$http.get(this.getOrderAPI(),this.jtiHeader).then(response=>{
							let commitIndex = 0
							let commitPoints = ['SecureProductCatalog','Cart','CheckoutSchoolInfo','BillingInfo', 'Address', 'CheckoutConfirm']
							let order = response.data.OrderDto
							// Created An Order
							if(order.orderLineItemCount>0){
								this.$store.commit('setShoppingCart', order.orderLineItemMap)
								this.processCart()
								++commitIndex
								if(this.hasNewSubscription){
									// Have School Info
									if(order.purchaseContactInfo.schoolInfo && order.purchaseContactInfo.schoolInfo.schoolType){
										this.$store.commit('setPID', order.purchaseContactInfo.schoolInfo.pid)
				      					this.$store.commit('setSchoolType', order.purchaseContactInfo.schoolInfo.schoolType)
				      					this.$store.commit('setState', order.purchaseContactInfo.schoolInfo.stateCode)
				      					this.$store.commit('setCountry', order.purchaseContactInfo.schoolInfo.countryCode)
					          			this.$store.commit('setZip', order.purchaseContactInfo.schoolInfo.institutionWebMenuItem.postalCode)
					          			this.$store.commit('setGradeLevels', order.purchaseContactInfo.schoolInfo.gradeLevels.split(','))
					          			this.$store.commit('setTitles', order.purchaseContactInfo.schoolInfo.titles.split(','))
					          			this.$store.commit('setUnknownName', order.purchaseContactInfo.schoolInfo.unknownName)
					          			++commitIndex
					          			// Have Credit Card Info
										if(order.purchaseContactInfo.creditCardInfo.validCardType){
											let cardInfo = {
							        			cardNumber : '',
							        			cardCode : '',
							        			expMonth : order.purchaseContactInfo.creditCardInfo.expMonth,
							        			expYear : order.purchaseContactInfo.creditCardInfo.expYear,
							        			cardType : order.purchaseContactInfo.creditCardInfo.cardTypeDisplay,
							        		}
							        		let splitName = order.purchaseContactInfo.creditCardInfo.fullName.split(' ')
											if(splitName.length==2){
												cardInfo.firstName = splitName[0]
							        			cardInfo.middleInitial = ''
							        			cardInfo.lastName = splitName[1]
											} else {
												cardInfo.firstName = splitName[0]
							        			cardInfo.middleInitial = splitName[1]
							        			cardInfo.lastName = splitName[2]
											}
											this.$store.commit('setCardInfo', cardInfo)
											this.$store.commit('setPhone', order.purchaseContactInfo.phone)
											this.$store.commit('setPhoneExt', order.purchaseContactInfo.phoneExt)
											++commitIndex
											// Have Address(es)
											if(order.purchaseContactInfo.billingAddress && order.purchaseContactInfo.billingAddress.countryCode){
												this.$store.commit('setBillingAddress', order.purchaseContactInfo.billingAddress)
												this.$store.commit('setShippingAddress', order.purchaseContactInfo.shippingAddress)
												if(JSON.stringify(order.purchaseContactInfo.billingAddress) !== JSON.stringify(order.purchaseContactInfo.shippingAddress)){
													this.$store.commit('setSameAddress', false)
												}
												++commitIndex
											}
										}
									}
								} else {
									++commitIndex
				          			if(order.purchaseContactInfo.creditCardInfo){
										let cardInfo = {
											cardNumber : '',
											cardCode : '',
											expMonth : order.purchaseContactInfo.creditCardInfo.expMonth,
											expYear : order.purchaseContactInfo.creditCardInfo.expYear,
											cardType : order.purchaseContactInfo.creditCardInfo.cardTypeDisplay,
										}
						        		let splitName = order.purchaseContactInfo.creditCardInfo.fullName.split(' ')
										if(splitName.length==2){
											cardInfo.firstName = splitName[0]
											cardInfo.middleInitial = ''
											cardInfo.lastName = splitName[1]
										} else {
											cardInfo.firstName = splitName[0]
											cardInfo.middleInitial = splitName[1]
											cardInfo.lastName = splitName[2]
										}
										this.$store.commit('setCardInfo', cardInfo)
										this.$store.commit('setPhone', order.purchaseContactInfo.phone)
										this.$store.commit('setPhoneExt', order.purchaseContactInfo.phoneExt)
										++commitIndex
										// Have Address(es)
										if(order.purchaseContactInfo.billingAddress && order.purchaseContactInfo.billingAddress.countryCode){
											this.$store.commit('setBillingAddress', order.purchaseContactInfo.billingAddress)
											this.$store.commit('setShippingAddress', order.purchaseContactInfo.shippingAddress)
											if(JSON.stringify(order.purchaseContactInfo.billingAddress) !== JSON.stringify(order.purchaseContactInfo.shippingAddress)){
												this.$store.commit('setSameAddress', false)
											}
											++commitIndex
										}
									}
								}
							}
							if(commitPoints.indexOf(this.$route.name)>commitIndex){
								this.$router.replace({name:commitPoints[commitIndex]})
							}
							this.autoFill()
						},response=>{
							this.$router.replace({name:'Cart'})
						})
					} else {
						this.processCart()
						this.autoFill()
					}
				}
			}
		}
	}
}
