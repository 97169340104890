<template>
    <component :is='userMgmt'></component>
</template>

<script>
    import Admin from 'components/classMgmt/Admin'
    import Classes from 'components/classMgmt/Classes'
    import { mapGetters } from 'vuex'
	export default {
        name: 'UserManagment',
        components: {Admin, Classes},
		computed : {
            ...mapGetters('platformData/profile', ['isHomeUser', 'isTeacher', 'isAdmin', 'isTrainer', 'isEmployee']),
            isClassCodeEnabledUser() {
                return this.$store.getters.getIsClassCodeEnabledUser 
            },
            userMgmt(){
                if (this.isHomeUser || this.isTeacher){
                    // Teachers or Home Users
                    if (this.isClassCodeEnabledUser && this.currentEnvironment == 'el') {
                        this.$router.push({name: 'Home'})
                    }
                    return 'Classes'
                } else if (this.isAdmin || this.isTrainer || this.isEmployee){
                    // Administrators, Certified Trainers, or EL Employees
                    return 'Admin'
                }
            }
		}
	}
</script>
