<template>
	<v-flex>
		<v-flex headline grey--text text--darken-2 class="tabHeading hidden-sm-and-up">{{$t('profile.subscriptionTab')}}</v-flex>
		<Messaging outline></Messaging>
		<v-flex v-if="$store.state.store.subscriptions && $store.state.store.subscriptions.length">
			<v-layout v-for="subscription in $store.state.store.subscriptions" :key="subscription.subscriptionID" class="mb-3">
				<v-flex xs2 md1 lg1 class="text-sm-center text-md-left">
					<img :src="productImgPath(subscription.productID)" 
						:alt="'ExploreLearning' +productDisplayName(subscription.productID)+' Logo'" 
						class="subLogo"
						:class="[
							{'greyed-out':showOptIn&&optOuts.includes(subscription.productID)},
							productDisplayName(subscription.productID).toLowerCase()+'-leaf'
						]"
					>
				</v-flex>
				<v-flex xs9 md7 lg8 class="pl-2 pr-2">
					<v-flex subheading class="subTitle">
						<span v-if="profile.roleID==4">{{productDisplayName(subscription.productID)}} {{$t('profile.parent')}}</span>
						<span v-else>
							<span v-if="isExpired(subscription)">{{$t('cart.expired')}}</span>
							<span v-if="subscription.pending">{{$t('cart.pending')}}</span>						
							{{productDisplayName(subscription.productID)}} {{getLicenseTypeName(subscription.licensingTypeID)}} {{$t('profile.license')}}
							<v-icon v-if="isEndpointSubFreemium(subscription)" @click="freemiumDialog=true" color="info">help_outline</v-icon>
						</span>
					</v-flex>
					<v-flex v-if="profile.roleID!=4" class="subOrg">
						{{subscription.subscriptionName}}
						<span v-if="subscription.licensingTypeID !== 1" class="grey--text">
							[{{subscription.studentsUsed}} of {{subscription.studentsCap}} {{$t('profile.students')}}]
						</span>
					</v-flex>
					<v-flex v-if="subscription.licensingTypeID !== 10" class="grey--text">
						{{(new Date(subscription.startsOn)).toLocaleDateString("en-US",dateFormat)}} - {{(new Date(subscription.expiresOn)).toLocaleDateString("en-US",dateFormat)}}
					</v-flex>
					<v-flex v-if="showOptIn && optOuts.includes(subscription.productID)" class="caption grey--text text--darken-2">
						(Disabled due to your <router-link class="grey--text text--darken-2" :to="{name:'OptIn'}">product selections</router-link>)
					</v-flex>
				</v-flex>
				<!-- subscription endpoint data used below -->
				<v-flex xs1 md4 v-if="subscription.fulfillable && (extendable(subscription)||expandable(subscription))">				
					<v-flex class="subIcon" hidden-md-and-up>
						<v-menu origin="center center" transition="scale-transition" left offset-y>
							<v-icon color="black" slot="activator">more_vert</v-icon>
							<v-list>
								<v-flex hidden-md-and-up v-if="extendable(subscription)">
									<v-list-tile @click="renew(subscription)" :class="{'disabled':inCart(extendable(subscription))}" :disabled="inCart(extendable(subscription))">
										<v-list-tile-avatar>
											<v-icon  :color="subscription.expired && subscription.productID==getProductCode()?'error':'black'">add_shopping_cart</v-icon>
											<v-icon  color="black">autorenew</v-icon>
										</v-list-tile-avatar>
										<v-list-tile-content>
											<v-list-tile-title>
												<span v-if="subscription.expired || subscription.licensingTypeID === 3" :class="{'error--text':subscription.expired && subscription.productID==getProductCode()}">
													{{$t('form.purchase')}}
												</span>
												<span v-else>{{$t('profile.renew')}}</span>
											</v-list-tile-title>
										</v-list-tile-content>
									</v-list-tile>
								</v-flex>
								<v-flex hidden-md-and-up v-if="expandable(subscription)">
									<v-list-tile @click="addSeats(subscription)" :class="{'disabled':inCart(expandable(subscription))}" :disabled="inCart(expandable(subscription))">
										<v-list-tile-avatar>
											<v-icon color="black">person_add</v-icon>
										</v-list-tile-avatar>
										<v-list-tile-content>
											<v-list-tile-title>
												{{$t('profile.addSeats')}}
											</v-list-tile-title>
										</v-list-tile-content>
									</v-list-tile>
								</v-flex>
							</v-list>
						</v-menu>
					</v-flex>
					<v-flex hidden-sm-and-down class="subIcon" v-if="expandable(subscription)" @click="addSeats(subscription)" :class="{'disabled':inCart(expandable(subscription))}">
						<v-icon color="black">person_add</v-icon>
						<p>{{$t('profile.addSeats')}}</p>
					</v-flex>
					<v-flex hidden-sm-and-down class="subIcon" v-if="extendable(subscription)" @click="renew(subscription)" :class="{'disabled':inCart(extendable(subscription))}">
						<span v-if="subscription.expired || subscription.licensingTypeID==3">
							<v-icon :color="subscription.expired && subscription.productID==getProductCode()?'error':'black'">add_shopping_cart</v-icon>
							<p :class="{'error--text':subscription.expired && subscription.productID==getProductCode()}">{{$t('form.purchase')}}</p>
						</span>
						<span v-else>
							<v-icon color="black">autorenew</v-icon>
							<p>{{$t('profile.renew')}}</p>
						</span>
					</v-flex>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-layout v-else>
			<v-flex class="text-xs-center grey--text my-5">
				{{$t('profile.noSubs')}}
			</v-flex>
		</v-layout>
		<v-flex class="text-xs-center">
			<v-btn v-if="showOptIn" large color="primary" class="elevation-0" :to="{name:'OptIn'}">
				Product Selections
			</v-btn>
			<v-btn v-if="displaySupport || hasUnfulfillable" outline large color="primary" :to="{name:'Support', query: {type:'sales'}}">
				{{$t('profile.contactSupport')}}
			</v-btn>
			<v-btn v-else-if="this.environments.length>1" outline large color="primary" :to="{name:'SecureProductCatalog'}">
				{{$t('profile.purchaseSub')}}
			</v-btn>
		</v-flex>
		<v-flex v-if="shoppingCartSize" class="text-xs-center">
			<v-btn large color="primary" :to="{name:'Cart'}">
				{{$t('navigation.cart')}}
			</v-btn>
		</v-flex>
		<v-dialog v-model="dialog.display" :max-width="dialog.width">
			<v-card>
				<form @submit.prevent="prepForCart()">
					<v-card-text>
						<v-alert :value="dialog.homeInfo" type="info" icon="info" outline class="mb-4">
							<div v-if="orderAction=='renew'" class="mb-2">
								We’re sorry. You can’t add students to your renewal.
							</div>
							<div v-else class="mb-2">
								We’re sorry. You can’t add students to your current subscription.
							</div>
							Reflex for home users is now Time4MathFacts. To purchase additional student licenses, please visit <a href="https://www.time4mathfacts.com/">Time4MathFacts</a>.
						</v-alert>
						<v-autocomplete
							v-if="orderAction=='renew'"
							v-model="dialog.numYears"
							:label="dialog.labelYears"
							v-validate="'required'"
							name="years"
							:error-messages="errors.collect('years')"
							v-bind:items="duration"
							item-text="label"
							item-value="value">
						</v-autocomplete>
						<v-text-field
							v-if="!this.dialog.blockExpansion"
							v-model="dialog.numStudents"
							:label="dialog.labelStudents"
							v-validate="'required|numeric|integer|min_value:'+dialog.seatMin+'|max_value:'+dialog.seatMax"
							name="students"
							:error-messages="errors.collect('students')">
						</v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" flat @click.stop="dialog.display=false">
							{{$t('navigation.close')}}
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="primary" type="submit" :disabled="dialog.blockExpansion">
							<v-icon>add_shopping_cart</v-icon>
						</v-btn>
					</v-card-actions>
				</form>
			</v-card>
    	</v-dialog>
    	<SiteLicenseModal></SiteLicenseModal>
		<FreemiumExplainedDialog
            :value="freemiumDialog"
            @closeDialog="freemiumDialog=false"
        />
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import FreemiumExplainedDialog from 'components/modals/FreemiumExplainedModal'
	import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
	import ShoppingCart from 'mixins/ShoppingCart'
	import SubscriptionHelper from 'mixins/SubscriptionHelper'
	import SiteLicenseModal from 'components/ecommerce/SiteLicenseModal'
	import LoginDetector from 'mixins/LoginDetector'
	import { mapGetters, mapState } from 'vuex'
	import Constants from 'mixins/Constants'
  	export default {
		name: 'Subscriptions',
		components : {FreemiumExplainedDialog, Messaging, SiteLicenseModal},
		mixins: [APIs, ProductInfo, ShoppingCart, SubscriptionHelper, LoginDetector, Constants],
		data(){
			return {
				dateFormat: {month: 'short', day: 'numeric', year: 'numeric'},
				dialog : {
					blockExpansion : false,
					currentSeats : 0,
					display : false,
					homeInfo : false,
					labelStudents : '',
					labelYears : '',
					numStudents : null,
					numYears : null,
					seatMax : Number.POSITIVE_INFINITY,
					seatMin : 1,
					width : 500
				},
				duration: [],
				freemiumDialog: false,
				orderItemID : null,
				orderAction : null
			}
  		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			displaySupport(){
				let displaySupport = false
				if(this.$store.state.store.subscriptions && this.$store.state.store.subscriptions.length){
					this.$store.state.store.subscriptions.forEach(sub=>{
						let type = sub.licensingTypeID
						if(type === 1 || type === 5 || type === 6 || type === 7){
							displaySupport = true
						}
					})
				}
				return displaySupport
			},
			hasUnfulfillable(){
				let fulfillable = true
				if(this.$store.state.store.subscriptions){
					this.$store.state.store.subscriptions.forEach(function(sub){
						if(!sub.fulfillable){
							fulfillable = false
						}
					})
				}
				return !fulfillable
			}
		},
		watch: {
			orderAction(action){
				if(action=='renew'){
					let seats = 0
					let activeOrderItemID = this.orderItemID
					let self = this
					if(this.$store.state.store.subscriptions) {
						this.$store.state.store.subscriptions.forEach(function(sub){
							if(sub.orderItems) {
								sub.orderItems.forEach(function(item){
									if(activeOrderItemID == item.orderItemID){
										if(self.licensingTypeID==3 && sub.studentsCap==5 && sub.studentsUsed){
											seats = sub.studentsUsed
										} else {
											seats = sub.studentsCap
										}
									}
								})
							}
						})
					}
					this.dialog.numStudents = seats
				} else if(action=='addSeats'){
					let seats = 0
					let activeOrderItemID = this.orderItemID
					if(this.$store.state.store.subscriptions){
						this.$store.state.store.subscriptions.forEach(function(sub){
							if(sub.orderItems){
								sub.orderItems.forEach(function(item){
									if(activeOrderItemID == item.orderItemID){
										seats = sub.studentsCap
									}
								})
							}
						})
					}
					this.dialog.currentSeats = seats
				}
			}
		},
		async created (){
			await this.$http.get(this.getSubscriptionsAPI(), this.jtiHeader).then(response => {
				this.$store.commit('setSubscriptions', response.data.ArrayList)
			}, error => {
				console.log(error)
			})
		},
		methods: {
			showDialog(labelYears, labelStudents){
				this.dialog.labelYears = labelYears
				this.dialog.labelStudents = labelStudents
				this.dialog.display = true
			},
			addSeats(subscription){
				let orderItemID = this.expandable(subscription)
				if(!this.inCart(orderItemID)){
					this.orderItemID = orderItemID
					this.orderAction = 'addSeats'
					this.dialog.seatMin = 1
					this.dialog.seatMax = Number.POSITIVE_INFINITY
					if(subscription.licensingTypeID === 9){
						this.dialog.width = 500
						this.dialog.homeInfo = true
						this.dialog.blockExpansion = true
					} else {
						this.dialog.width = 300
						this.dialog.homeInfo = false
						this.dialog.blockExpansion = false
					}
					this.showDialog('',this.$t('profile.fieldAdditionalSeats'))
				}
			},
			isEndpointSubFreemium(sub) {
				if(sub){
                const isExemption = this.licenseTypeIsExpiredExempted(sub.productID, sub.licensingTypeID)
                return sub.expired && isExemption
				} else {
					return false
				}
			},
			isExpired(sub){
				if(this.licenseTypeIsExpiredExempted(sub.productID, sub.licensingType)){
					return false
				} else {
					return sub.expired
				}
			},
			renew(subscription){
				let orderItemID = this.extendable(subscription)
				if(!this.inCart(orderItemID)){
					this.orderItemID = orderItemID
					this.orderAction = 'renew'
					this.dialog.blockExpansion = false
					if(subscription.licensingTypeID == 9){
						this.dialog.width = 500
						this.dialog.homeInfo = true
						this.dialog.seatMin = 1
						this.dialog.seatMax = subscription.studentsCap
						this.duration = this.buildDuration(1)
					} else {
						this.dialog.width = 300
						this.dialog.homeInfo = false
						this.dialog.seatMin = 10
						this.dialog.seatMax = Number.POSITIVE_INFINITY
						this.duration = this.buildDuration(3)
					}
					if(subscription.expired || subscription.licensingTypeID === 3){
						this.showDialog(this.$t('profile.fieldYears'),this.$t('profile.fieldSeats'))
					} else {
						this.showDialog(this.$t('profile.fieldAdditionalYears'),this.$t('profile.fieldSeats'))
					}
				}
			},
			prepForCart: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.dialog.display = false
					let seats = Number(this.dialog.numStudents)
					let years = 0
					let options = {}
					if(this.orderAction == 'addSeats'){
						options.currentSeats = this.dialog.currentSeats
					} else {
						years = this.dialog.numYears
					}
					this.addToCart(this.orderItemID, seats, years, options)
					this.dialog.field = null
				}
			},
			expandable(sub){
				let expandable = false
				if(sub.orderItems){
					sub.orderItems.forEach(function(orderItem){
						if(orderItem && orderItem.orderItemType){
							let type = orderItem.orderItemType.toLowerCase()
							if(type == 'expansion' || type == 'expandpending'){
								expandable = orderItem.orderItemID
							}
						}
					})
				}
				return expandable
			},
			extendable(sub){
				let extendable = false
				if(sub.orderItems&&sub.orderItems.length){
					sub.orderItems.forEach(function(orderItem){
						if(orderItem && orderItem.orderItemType){
							let type = orderItem.orderItemType.toLowerCase()
							if(type == 'renew'){
								extendable = orderItem.orderItemID
							}
						}
					})
				}
				return extendable
			},
			buildDuration(maxYears){
				let duration = []
				for (let i = 1; i<=maxYears; i++){
					duration.push({label: i+' '+this.$t('catalog.duration'), value: i})
				}
				return duration
			}
		}
  	}
</script>

<style scoped lang="less">
	.subLogo{
		width: 50px;
		margin-top: 5px;
	}
	.subTitle {
		font-weight: 500 !important;
		position: relative;
		.v-icon{
			font-size: 20px;
			cursor: pointer;
		}
	}
	.subOrg {
		color: #1976D2;
		text-transform: uppercase;
		font-weight: 350;
	}
	.subIcon {
		text-align: center;
		vertical-align: top;
		color: black;
		margin: 10px 8px 0;
		display: inline-block;
		cursor: pointer;
		float: right;
	}
	.disabled {
		color:#A6A6A6 !important;
		cursor: default;
		i {
			color:#A6A6A6 !important;
		}
		.error--text {
			color:#A6A6A6 !important;
		}
	}
	.v-alert {
		border-radius: 4px !important;
	}
	.greyed-out {
        filter: grayscale(1);
    }
	.greyed-out.frax-leaf{
		opacity: 0.4;
	}
    .greyed-out.reflex-leaf{
        opacity: 0.7;
    }
    .greyed-out.gizmos-leaf{
        opacity: 0.45;
    }
    .greyed-out.science4us-leaf{
        opacity: .9;
    }
</style>
