import APIs from 'mixins/APIs'
import LoginDetector from 'mixins/LoginDetector'
import { mapState } from 'vuex'
export default {
	mixins: [APIs, LoginDetector],
	data(){
			return {
				dialog : {
					display : false,
					item : null
				},
				change : {
					display : false,
					item : null
				},
				orderProcessed : false,
				totalPrice : 0
			}
		},
	computed: {
		...mapState('platformData/app', ['jti']),
		shoppingCart(){
			return this.$store.state.store.shoppingCart
		},
		shoppingCartSize(){
			return Object.keys(this.shoppingCart).length
		},
		hasNewSubscription(){
			let newSubscription = false
			for(let orderItemKey in this.shoppingCart){
				let lineItem = this.shoppingCart[orderItemKey]
				if(lineItem.orderItem && lineItem.orderItem.orderItemType=='Purchase'){
					newSubscription = true
				}
			}
			return newSubscription
		}
	},
	watch : {
		hasNewSubscription(){
			this.$store.commit('setCheckoutSkipSchool', !this.hasNewSubscription)
		}
	},
	methods: {
		addToCart(orderItemID, seats, years, options={modalBypass:false, currentSeats:0}){
			let cartItem = {
				orderItemID: orderItemID,
				seats: Number(seats),
				years: Number(years),
				currentSeats: 0
			}
			if(options.currentSeats){
				cartItem.currentSeats = options.currentSeats
			}
			if(!options.modalBypass && cartItem.seats + cartItem.currentSeats >= 40){
				this.$store.commit('setCartItemLimbo', cartItem)
				this.$store.commit('setSiteLicenseModal', true)
			} else {
				if(this.loggedIn){
					this.$http.post(this.getAddUpdateOrderItem(orderItemID), cartItem, this.jtiHeader).then(response => {
						this.$store.commit('setShoppingCart', response.data.OrderDto.orderLineItemMap)
						this.$store.commit('setCheckoutSkipSchool', !this.hasNewSubscription)
						this.totalPrice = response.data.OrderDto.totalPrice
						this.orderProcessed = true
					}, error => {
						this.removefromCart(orderItemID)
					})
				} else {
					this.$store.commit('addToShoppingCart', cartItem)
				}
			}
		},
		inCart(orderItemID){
			let inCart = false
			if(this.shoppingCart[orderItemID]){
				inCart=true
			}
  		return inCart
		},
		processCart(){
			this.orderProcessed = false
			for(var cartItemID in this.shoppingCart){
				let cartItem = this.shoppingCart[cartItemID]
				if(cartItem.fullYears){
					cartItem.years = cartItem.fullYears
				}
				let orderItemID = cartItem.orderItemID || cartItem.orderItem.orderItemID
				this.$http.post(this.getAddUpdateOrderItem(orderItemID), cartItem, this.jtiHeader).then(response => {
					this.$store.commit('setShoppingCart', response.data.OrderDto.orderLineItemMap)
					this.$store.commit('setCheckoutSkipSchool', !this.hasNewSubscription)
					this.totalPrice = response.data.OrderDto.totalPrice
					this.orderProcessed = true
				}, error => {
					this.removefromCart(cartItemID)
				})
			}
		},
		removefromCart(orderItemID){
			this.$http.delete(this.getAddUpdateOrderItem(orderItemID), this.jtiHeader).then(response => {
				this.$store.commit('setShoppingCart', response.data.OrderDto.orderLineItemMap)
				this.totalPrice = response.data.OrderDto.totalPrice
			}, error => {
				console.log(error)
			})
		},
		resetCart(){
			this.$store.commit('setShoppingCart', {})
		},
		showCostModal(cartItem){
			this.dialog.item = cartItem
			this.dialog.display = true
		},
		showChangeModal(cartItem){
			this.change.item = cartItem
			this.change.display = true
		}
	}
}
